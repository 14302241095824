import React, { useState, useEffect, useRef, useCallback } from 'react';
import * as THREE from 'three';
import { saveAs } from 'file-saver';
import { v4 as uuidv4 } from 'uuid';
import SceneManager from './SceneManager';
import './App.css';

function safelyFormatValue(value) {
  return typeof value === 'number' ? value.toFixed(2) : value.toString();
}

function ActionTracker() {
  const [actions, setActions] = useState({});

  const addAction = useCallback((action) => {
    const blenderAction = {
      ...action,
      threePosition: [...action.position],
      threeRotation: [...action.rotation],
      blenderPosition: [action.position[0], action.position[2], -action.position[1]],
      blenderRotation: [action.rotation[0], action.rotation[2], -action.rotation[1]],
      scale: [...action.scale]
    };
    setActions((prevActions) => ({
      ...prevActions,
      [action.partName]: blenderAction
    }));
  }, []);

  const clearActions = useCallback(() => {
    setActions({});
  }, []);

  return { actions, addAction, clearActions };
}

function App() {
  const [viewMode, setViewMode] = useState('texture');
  const [prompt, setPrompt] = useState('A forest scene.');
  const [generatedObject, setGeneratedObject] = useState(null);
  const [sessionId, setSessionId] = useState(() => uuidv4().replace(/-/g, '').substring(0, 16));
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [transformMode, setTransformMode] = useState('translate');
  const [selectedPartName, setSelectedPartName] = useState(null);
  const canvasRef = useRef(null);
  const sceneManagerRef = useRef(null);
  const actionTracker = ActionTracker();
  const [imageFile, setImageFile] = useState(null);
  const [cubeAIPrompt, setCubeAIPrompt] = useState('');
  const [spinningParts, setSpinningParts] = useState([]);
  const [statusMessages, setStatusMessages] = useState([]);
  const [isGLBLoaded, setIsGLBLoaded] = useState(false);

  useEffect(() => {
    sceneManagerRef.current = new SceneManager(canvasRef.current, actionTracker.addAction, setSelectedPartName, spinningParts);
    sceneManagerRef.current.startAnimation();

    return () => {
      if (sceneManagerRef.current) {
        sceneManagerRef.current.dispose();
        sceneManagerRef.current = null;
      }
    };
  }, []);

// UseEffect to load the model when generatedObject changes
  useEffect(() => {
    if (sceneManagerRef.current && generatedObject) {
      sceneManagerRef.current.loadModel(generatedObject.url).then(() => {
        setIsGLBLoaded(true); // Set GLB as loaded
      });
    } else {
      setIsGLBLoaded(false); // No GLB file, set as not loaded
    }
  }, [generatedObject]);

  // UseEffect to update the view mode when viewMode changes
  useEffect(() => {
    if (sceneManagerRef.current) {
      sceneManagerRef.current.setViewMode(viewMode);
    }
  }, [viewMode]);
  
  useEffect(() => {
    if (sceneManagerRef.current) {
      sceneManagerRef.current.setTransformMode(transformMode);
    }
  }, [transformMode]);

  useEffect(() => {
    let animationId;
    const spin = () => {
      spinningParts.forEach(partName => {
        const part = sceneManagerRef.current.parts.find(p => p.name === partName);
        if (part) {
          part.rotation.y += 0.01;
        }
      });
      // Schedule the next frame
      animationId = requestAnimationFrame(spin);
    };
    if (spinningParts.length > 0) {
      spin();
    }
    return () => cancelAnimationFrame(animationId);
  }, [spinningParts]);

  useEffect(() => {
    const handleUnload = () => {
      if (sessionId) {
        const data = new Blob([JSON.stringify({ session_code: sessionId })], {
          type: 'application/json',
        });
        navigator.sendBeacon('http://172.178.76.173:8081/delete-glb', data);
      }
    };
    window.addEventListener('beforeunload', handleUnload);
    return () => {
      window.removeEventListener('beforeunload', handleUnload);
    };
  }, [sessionId]);

  const handlePromptSubmit = async (e) => {
    e.preventDefault();
    setError('');
    if (!sessionId) {
      setError('Please enter a session ID');
      return;
    }
    setIsLoading(true);

    const actionDescriptions = Object.values(actionTracker.actions)
      .map((action) => {
        return `User ${action.type}d part "${action.partName}":
          Three.js Position: (${action.threePosition.map(safelyFormatValue).join(', ')})
          Three.js Rotation: (${action.threeRotation.map(safelyFormatValue).join(', ')})
          Blender Position: (${action.blenderPosition.map(safelyFormatValue).join(', ')})
          Blender Rotation: (${action.blenderRotation.map(safelyFormatValue).join(', ')})
          Scale: (${action.scale.map(safelyFormatValue).join(', ')})`;
      })
      .join('\n');

//     const fullPrompt = `The following transformations are provided in both Three.js (Y-up) and Blender (Z-up) coordinate systems:
// ${actionDescriptions}

// Important notes:
// 1. Three.js uses a Y-up coordinate system, while Blender uses a Z-up system.
// 2. The Blender coordinates provided are an attempt to convert from Three.js to Blender, but may not be perfect.
// 3. When applying transformations, prioritize the coordinate system that makes the most sense for the current context.
// 4. If the Blender script is making changes along different axes than expected, refer to the Three.js coordinates and adjust the script accordingly.

// Please apply these transformations carefully, considering both coordinate systems, and then proceed with the following instructions:
// ${prompt}`;

    const fullPrompt = prompt;

    try {
      let imagePath = null;
      if (imageFile) {
        const formData = new FormData();
        formData.append('file', imageFile);
        const uploadResponse = await fetch('http://172.178.76.173:8081/upload-image', {
          method: 'POST',
          body: formData,
        });
        if (!uploadResponse.ok) {
          throw new Error('Failed to upload image');
        }
        const uploadData = await uploadResponse.json();
        imagePath = uploadData.file_path;
      }

      const response = await fetch('http://172.178.76.173:8081/generate-scene', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          prompt: fullPrompt,
          session_code: sessionId,
          image_path: imagePath,
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to generate scene');
      }

      const data = await response.json();
      setSessionId(data.session_code);

      const glbResponse = await fetch(`http://172.178.76.173:8081/download-glb/${data.session_code}`);
      if (!glbResponse.ok) {
        throw new Error('Failed to download GLB file');
      }

      const glbBlob = await glbResponse.blob();
      const glbUrl = URL.createObjectURL(glbBlob);

      if (sceneManagerRef.current) {
        sceneManagerRef.current.clearScene();
      }

      setGeneratedObject({
        url: glbUrl,
        seed: Math.floor(Math.random() * 1000000),
      });

      // Flush changes after API call
      actionTracker.clearActions();
    } catch (error) {
      console.error('Error generating 3D model:', error);
      setError(error.message);
    } finally {
      setIsLoading(false);
    };
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImageFile(file);
    }
  };

  const handleCanvasClick = (event) => {
    if (sceneManagerRef.current) {
      const rect = canvasRef.current.getBoundingClientRect();
      const x = event.clientX - rect.left;
      const y = event.clientY - rect.top;
      const selectedPart = sceneManagerRef.current.selectPart(x, y);
      if (selectedPart && !spinningParts.includes(selectedPart.name)) {
        setSelectedPartName(selectedPart.name); // Allow selection if not spinning
      } else {
        console.log('Cannot select a mesh in-progress.');
      }  
    }
  };

  const handleCubeAIPromptSubmit = async (e) => {
    e.preventDefault();
    setError('');
    // setIsLoading(true);
  
    const newStatusMessage = `Your preview mesh for ${selectedPartName} is now in progress...`;
    setStatusMessages((prevMessages) => [...prevMessages, newStatusMessage]);
    setSpinningParts((prevParts) => [...prevParts, selectedPartName]); // Start spinning the selected part
  
    try {
      const response = await fetch('http://172.178.76.173:8081/generate-csm-mesh', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          prompt: cubeAIPrompt,
          session_id: sessionId,
        }),
      });
  
      if (!response.ok) {
        throw new Error('Failed to generate Cube AI Mesh');
      }
  
      const data = await response.json();
      console.log('Generated Cube AI Mesh:', data);
  
      // Call the function to replace the GLB model after generating the mesh
      await handleReplaceGLBModel(selectedPartName);
    } catch (error) {
      console.error('Error generating Cube AI Mesh:', error);
      setError(error.message);
    } finally {
      // setIsLoading(false);
      setStatusMessages((prevMessages) => prevMessages.filter((msg) => msg !== newStatusMessage));
      setSpinningParts((prevParts) => prevParts.filter((part) => part !== selectedPartName)); // Stop spinning the part
    }
  };

  const handleReplaceGLBModel = async (partName) => {
    try {
      const response = await fetch('http://172.178.76.173:8081/replace-model', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          session_id: sessionId,
          placeholder_name: partName,
          glb_path: `/tmp/output_${sessionId}/output_scene.glb`,
        }),
      });
  
      if (!response.ok) {
        throw new Error('Failed to replace model');
      }

      // Reload the modified GLB file
      const blob = await response.blob();
      const url = URL.createObjectURL(blob);

      if (sceneManagerRef.current) {
        await sceneManagerRef.current.loadModel(url); // Reload the model
      }

      setSelectedPartName(null); // Clear the selected part name
    } catch (error) {
      console.error('Error replacing model:', error);
      setError(error.message);
    }
  };

  const handleDeleteSelectedPart = async () => {
    setError('');
    setIsLoading(true);

    try {
      const response = await fetch('http://172.178.76.173:8081/delete-model', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          object_name: selectedPartName,
          glb_path: `/tmp/output_${sessionId}/output_scene.glb`,
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to delete object');
      }

      // Reload the modified GLB file
      const blob = await response.blob();
      const url = URL.createObjectURL(blob);

      if (sceneManagerRef.current) {
        await sceneManagerRef.current.loadModel(url); // Reload the model
      }

      setSelectedPartName(null); // Clear the selected part name

    } catch (error) {
      console.error('Error deleting object:', error);
      setError(error.message);
    } finally {
      setIsLoading(false);
    };
  };

  const handleExportSceneAsGLB = async () => {
    setError('');
    setIsLoading(true);

    if (!sessionId) {
      setError('Please enter a session ID');
      return;
    }

    try {
      const response = await fetch(`http://172.178.76.173:8081/download-glb/${sessionId}`, {
        method: 'GET',
      });

      if (!response.ok) {
        throw new Error('Failed to download GLB file');
      }

      const blob = await response.blob();
      saveAs(blob, `output_scene_${sessionId}.glb`);
    } catch (error) {
      console.error('Error downloading GLB file:', error);
      setError(error.message);
    } finally {
      setIsLoading(false);
    };
  };

  const handleGenerateCubeScene = async () => {
    if (!sessionId) {
      setError('Please enter a session ID');
      return;
    }
  
    try {
      setIsLoading(true);
      // Fetch the currently loaded GLB blob from the URL
      const glbResponse = await fetch(generatedObject.url);
      const glbBlob = await glbResponse.blob();
  
      // Convert the blob into a File object
      const glbFile = new File([glbBlob], `output_scene_${sessionId}.glb`, {
        type: "model/gltf-binary",
      });
  
      // Create a FormData object to send the GLB file and session ID
      const formData = new FormData();
      formData.append('session_code', sessionId);
      formData.append('glb_file', glbFile);
  
      // Send the request to the /generate-cube-scene endpoint
      const response = await fetch('http://172.178.76.173:8081/generate-cube-scene', {
        method: 'POST',
        body: formData, // Send the FormData object
      });
  
      if (!response.ok) {
        throw new Error('Failed to generate cube scene');
      }
  
      // The response contains the GLB file, so we handle it as a blob
      const generatedGLBBlob = await response.blob();
  
      // Create a URL for the newly generated GLB file
      const generatedGLBUrl = URL.createObjectURL(generatedGLBBlob);
  
      // Now, load the newly generated GLB file into the scene
      if (sceneManagerRef.current) {
        await sceneManagerRef.current.loadModel(generatedGLBUrl);
      }
  
      console.log('Generated cube scene and loaded new GLB file');
    } catch (error) {
      console.error('Error generating cube scene:', error);
      setError(error.message);
    } finally {
      setIsLoading(false);
    }
  };
  
  return (
    <div className="app-container" tabIndex={0}>
      {isLoading && (
        <div className="loading-overlay">
          <div className="spinner"></div>
        </div>
      )}
      <header className="header">
        <div className="transform-buttons">
          <button className="button" onClick={() => setTransformMode('translate')}>Translate (T)</button>
          <button className="button" onClick={() => setTransformMode('rotate')}>Rotate (R)</button>
          <button className="button" onClick={() => setTransformMode('scale')}>Scale (S)</button>
        </div>
        <div className="button-container">
          <button className="button" onClick={() => setViewMode('wireframe')}>Wireframe View</button>
          <button className="button" onClick={() => setViewMode('geometry')}>Geometry View</button>
          <button className="button" onClick={() => setViewMode('texture')}>Texture View</button>
          <button className="cube-ai-button" onClick={handleGenerateCubeScene} disabled={!isGLBLoaded}>Convert to Cube AI Meshes</button>
          <button className="export-button" onClick={handleExportSceneAsGLB} disabled={!isGLBLoaded}>Export Scene as GLB</button>
        </div>
        {statusMessages.map((message, index) => (
          <div key={index} className="status-message">{message}</div>
        ))}
      </header>
      <div className="main-content">
        <div className="canvas-container">
          <canvas ref={canvasRef} onClick={handleCanvasClick} />
          {selectedPartName && (
            <div className="selected-part-info">
              <div className="selected-part-name">Selected Part: {selectedPartName}</div>
              <input
                type="text"
                value={cubeAIPrompt}
                onChange={(e) => setCubeAIPrompt(e.target.value)}
                placeholder="Enter Cube AI prompt..."
                className="input-field"
                disabled={isLoading}
              />
              <button
                className="generate-button"
                onClick={handleCubeAIPromptSubmit}
                disabled={isLoading}
              >
                Generate Cube AI Mesh
              </button>
              <button
                className="delete-button"
                onClick={handleDeleteSelectedPart}
                disabled={isLoading}
              >
                Delete Selected Part
              </button>
            </div>
          )}
        </div>
      </div>
      <aside className="sidebar">
        <form onSubmit={handlePromptSubmit} className="input-form">
          <input
            type="text"
            value={sessionId}
            onChange={(e) => setSessionId(e.target.value)}
            placeholder="Enter session ID"
            className="input-field"
            disabled={isLoading}
          />
          <input
            type="text"
            value={prompt}
            onChange={(e) => setPrompt(e.target.value)}
            placeholder="Enter your prompt..."
            className="input-field"
            disabled={isLoading}
          />
          <input
            type="file"
            accept="image/*"
            onChange={handleImageChange}
            className="input-field"
            disabled={isLoading}
          />
          <button type="submit" className="generate-button" disabled={isLoading}>
            {isLoading ? 'Generating...' : 'Generate'}
          </button>
        </form>
        {error && <div className="error-message">{error}</div>}
      </aside>
    </div>
  );
}

export default App;
