import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { TransformControls } from 'three/examples/jsm/controls/TransformControls';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer';
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass';
import { OutlinePass } from 'three/examples/jsm/postprocessing/OutlinePass';
import { ShaderPass } from 'three/examples/jsm/postprocessing/ShaderPass';
import { FXAAShader } from 'three/examples/jsm/shaders/FXAAShader';

class SceneManager {
  constructor(canvas, addAction, setSelectedPartName, spinningParts) {
    this.canvas = canvas;
    this.isDisposed = false;
    this.addAction = addAction;
    this.setSelectedPartName = setSelectedPartName;
    this.spinningParts = spinningParts;
    this.initScene();
  }

  initScene() {
    this.scene = new THREE.Scene();
    this.camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000);
    this.renderer = new THREE.WebGLRenderer({ canvas: this.canvas, antialias: true });
    this.renderer.setSize(window.innerWidth, window.innerHeight);

    this.camera.position.set(0, 0, 5);

    this.raycaster = new THREE.Raycaster();
    this.mouse = new THREE.Vector2();

    this.orbitControls = new OrbitControls(this.camera, this.renderer.domElement);
    this.transformControls = new TransformControls(this.camera, this.renderer.domElement);
    this.scene.add(this.transformControls);

    this.parts = [];
    this.selectedPart = null;

    this.ambientLight = new THREE.AmbientLight(0xffffff, 2.0);
    this.ambientLight.castShadow = true;
    this.scene.add(this.ambientLight);

    this.transformControls.addEventListener('dragging-changed', (event) => {
      this.orbitControls.enabled = !event.value;
    });

    this.transformControls.addEventListener('change', () => {
      if (this.selectedPart) {
        this.addAction({
          type: 'update',
          partName: this.selectedPart.name,
          position: this.selectedPart.position.toArray(),
          rotation: this.selectedPart.rotation.toArray(),
          scale: this.selectedPart.scale.toArray()
        });
      }
    });

    window.addEventListener('resize', () => this.onWindowResize());

    // Setup EffectComposer for highlighting
    this.composer = new EffectComposer(this.renderer);
    this.renderPass = new RenderPass(this.scene, this.camera);
    this.composer.addPass(this.renderPass);

    this.outlinePass = new OutlinePass(new THREE.Vector2(window.innerWidth, window.innerHeight), this.scene, this.camera);
    this.outlinePass.edgeThickness = 1.0// 1.0; // Adjust edge thickness
    this.outlinePass.edgeStrength = 3.0// 1.5; // Adjust edge strength
    this.outlinePass.visibleEdgeColor.set(0xffffff); // Set edge color
    this.outlinePass.hiddenEdgeColor.set(0x000000); // Set hidden edge color to black
    this.composer.addPass(this.outlinePass);

    const fxaaPass = new ShaderPass(FXAAShader);
    fxaaPass.uniforms['resolution'].value.set(1 / window.innerWidth, 1 / window.innerHeight);
    this.composer.addPass(fxaaPass);

    this.selectedObjects = [];
    this.animate = this.animate.bind(this);
  }

  loadModel(url) {
    this.clearScene();

    return new Promise((resolve, reject) => {
      const loader = new GLTFLoader();
      loader.load(url, (gltf) => {
        gltf.scene.traverse((child) => {
          if (child.isMesh) {
            const group = new THREE.Group();
            group.add(child.clone());
            child.getWorldPosition(group.position);
            group.children[0].position.set(0, 0, 0);
            group.name = child.name || `Part_${this.parts.length}`;

            const sprite = this.createTextSprite(group.name);
            sprite.position.set(0, 1, 0);
            group.add(sprite);

            this.parts.push(group);
            this.scene.add(group);
          }
        });
        resolve();
      }, undefined, reject);
    });
  }

  clearScene() {
    this.parts.forEach(part => {
      part.traverse((child) => {
        if (child.geometry) child.geometry.dispose();
        if (child.material) {
          if (Array.isArray(child.material)) {
            child.material.forEach(material => material.dispose());
          } else {
            child.material.dispose();
          }
        }
      });
      this.scene.remove(part);
    });
    this.parts = [];

    this.transformControls.detach();
    this.selectedPart = null;
  }

  createTextSprite(message) {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    context.font = 'Bold 20px Arial';
    context.fillStyle = 'rgba(255,255,255,1)';
    context.fillText(message, 0, 24);

    const texture = new THREE.CanvasTexture(canvas);
    const spriteMaterial = new THREE.SpriteMaterial({ map: texture });
    const sprite = new THREE.Sprite(spriteMaterial);
    sprite.scale.set(2, 1, 1);
    return sprite;
  }

  setViewMode(mode) {
    this.parts.forEach(part => {
      const mesh = part.children[0];
      if (!mesh.userData.originalMaterial) {
        mesh.userData.originalMaterial = mesh.material;
      }

      switch (mode) {
        case 'wireframe':
          mesh.material = new THREE.MeshBasicMaterial({ wireframe: true, color: 0xffffff });
          break;
        case 'geometry':
          mesh.material = new THREE.MeshBasicMaterial({ color: 0xcccccc });
          break;
        case 'texture':
          mesh.material = mesh.userData.originalMaterial;
          break;
      }
    });
  }

  selectPart(x, y) {
    this.mouse.x = (x / window.innerWidth) * 2 - 1;
    this.mouse.y = -(y / window.innerHeight) * 2 + 1;

    this.raycaster.setFromCamera(this.mouse, this.camera);

    const intersects = this.raycaster.intersectObjects(this.parts.flatMap(part => part.children));

    if (intersects.length > 0) {
      const selectedPart = intersects[0].object.parent;
      if (this.selectedPart !== selectedPart && !this.spinningParts.includes(selectedPart.name)) {
        this.transformControls.detach();
        this.selectedPart = selectedPart;
        this.transformControls.attach(this.selectedPart);
        this.setSelectedPartName(this.selectedPart.name);  // Update selected part name
        this.addSelectedObjects(this.selectedPart.children[0]);
      }
    } else {
      this.transformControls.detach();
      this.selectedPart = null;
      this.setSelectedPartName(null);  // Clear selected part name
      this.clearSelectedObjects();
    }
  }

  addSelectedObjects(object) {
    this.selectedObjects.pop();
    this.selectedObjects.push(object);
    this.outlinePass.selectedObjects = this.selectedObjects;
  }

  clearSelectedObjects() {
    this.selectedObjects = [];
    this.outlinePass.selectedObjects = this.selectedObjects;
  }

  setTransformMode(mode) {
    this.transformControls.setMode(mode);
  }

  getTransformations() {
    return this.parts.reduce((acc, part) => {
      acc[part.name] = {
        position: part.position.toArray(),
        rotation: part.rotation.toArray(),
        scale: part.scale.toArray(),
      };
      return acc;
    }, {});
  }

  onWindowResize() {
    this.camera.aspect = window.innerWidth / window.innerHeight;
    this.camera.updateProjectionMatrix();
    this.renderer.setSize(window.innerWidth, window.innerHeight);
    this.composer.setSize(window.innerWidth, window.innerHeight);
  }

  animate() {
    if (this.isDisposed) return;
    this.animationFrameId = requestAnimationFrame(this.animate);
    if (this.renderer && this.scene && this.camera) {
      this.renderer.autoClear = false; // Ensure autoClear is false
      this.renderer.clear();
      this.composer.render();
    }
  }

  startAnimation() {
    if (!this.isDisposed) {
      this.animate();
    }
  }

  dispose() {
    this.isDisposed = true;

    if (this.animationFrameId) {
      cancelAnimationFrame(this.animationFrameId);
    }

    this.clearScene();

    window.removeEventListener('resize', this.onWindowResize);

    this.orbitControls.dispose();
    this.transformControls.dispose();
    this.renderer.dispose();

    this.scene = null;
    this.camera = null;
    this.renderer = null;
    this.raycaster = null;
    this.orbitControls = null;
    this.transformControls = null;
  }
}

export default SceneManager;
